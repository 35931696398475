import React from 'react';
import '../pagecss/CompletedProjects.css';
import certi from '../assets/certi.jpeg';

const CompletedProjects = () => {
    return (
        <div className="completed-projects-section">
            <h2>2021</h2>
            <ul>
                <li>ShodhGuru Labs has successfully organized its first Workshop at the <b>University of Uyo, Nigeria</b> on <b>"Formal Ontological Reasoning and Representation of Knowledge Laboratory (FORRK Lab),"</b> on October 28, 2021.</li>
                <li>Dr. Sanju Tiwari has conducted this workshop on "Ontology Development: Construction, Evaluation, and Publication". Link: <a href="https://www.youtube.com/watch?v=KRd6hn3YUls&t=21s" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=KRd6hn3YUls&t=21s</a></li>
            </ul>

            <h2>2022</h2>
            <ul>
                <li>ShodhGuru Labs has successfully organized its second Workshop at the <b>Abbas Laghrour University of Khenchela, Algeria</b> on <b>"Role of Semantic IoT: Reasoning and Representation"</b> by ShodhGuru Research Labs, India on May 8, 2022. This workshop has been conducted by Dr. Sanju Tiwari and Vishal Lama (Siemens, India) with SPARQL hands-on practice session.</li>
                <li>ShodhGuru Labs has successfully organized its Third Workshop at the <b>Cooperative University, Kenya</b>, on <b>"From Ontologies to Knowledge Graphs: An Overview"</b> by ShodhGuru Research Labs, India on July 8, 2022. This workshop has been conducted by Dr. Sanju Tiwari and Dr. Ronak Panchal (Cognizant, India) with Knowledge Graph Construction hands-on practice session.</li>
                <li>ShodhGuru Labs has successfully organized its Fourth Workshop at the <b>Universidad Tecnologica de Panama, Panama</b> by ShodhGuru Research Labs, India on September 8, 2022. This workshop has been conducted by Dr. Ajit Kumar (Soongsil University, Seoul, South Korea) on <b>"Natural Language Processing and its Role in Question/Answering"</b> and Dr. Eraldo R. Fernandes (<b>Leuphana University, Lunenburg, Germany</b>) on <b>"Question & Answering: A Proxy for Reading Comprehension"</b> with hands-on practice session.</li>
                <li>ShodhGuru Labs has successfully organized its Fifth Workshop at <b>Federal Institute of Education, Science and Technology of Goiás - Campus Jataí, Brazil</b> with Gustavo de Asis Costa on November 8, 2022. This workshop has organized on the theme of <b>"Open Research Knowledge Graphs"</b> with hands on session by Allard Oelen, TIB, Hannover, Germany; Azanzi Jiomekong, University of Yaounde, Cameroon; Sanju Tiwari, ORKG Curator.</li>
            </ul>

            <h2>2023</h2>
            <p>Shodhguru Research Labs organized One - Week Online Faculty Development Program on Foundations and Applications of AI in Healthcare Dept. of Computer Science and Engineering in association jointly with ShodhGuru Innovation and Research Labs at <b>ANIL NEERUKONDA INSTITUTE OF TECHNOLOGY AND SCIENCES, Vishakhapatnam, India</b> on 16 ~ 20 January, 2023.</p>
            
            <ul>
            
                <li><p>Speakers:</p>
                    <ul>
                        <li>Orchid Chetia Phukan, University of Tartu, Estonia</li>
                        <li>Kaushik Roy, Artificial Intelligence Institute at the University of South Carolina (AIISC), USA</li>
                        <li>Ron Ojino, Cooperative University, Kenya</li>
                        <li>Ronak Panchal, Cognizant, India</li>
                        <li>Sanju Tiwari, Shodhguru Research Labs, India & UAT, Mexico</li>
                    </ul>
                </li>
            </ul>

            <p>Shodhguru Research Labs organized One - Week Online Faculty Development Program on Natural Language Modeling and Semantic Modeling, Dept. of Computer Science and Engineering in association jointly with ShodhGuru Innovation and Research Labs at ANIL NEERUKONDA INSTITUTE OF TECHNOLOGY AND SCIENCES, Vishakhapatnam, India on 3 ~ 8 APRIL, 2023.</p>
            
            <ul>
                <li><p>Speakers:</p>
                    <ul>
                        <li>Ananya Hooda, IIT Bhilai, India on Natural Language Processing using Python</li>
                        <li>Saurav Joshi, University of South California, USA on Foundation of GPT, GP2, GPT3 and its related model</li>
                        <li>Nandana Mihindululasoorya, IBM Research Ireland on Foundation of Knowledge Graphs to its Applications</li>
                        <li>Sanju Tiwari, Shodhguru Resaerch Labs, India on Semantic Modeling and Representation</li>
                        <li>Shivansh Gupta, Scaler, India, on Graph Learning and Analytics</li>
                        <li>Tek Raj Chhetri, Universitat Innsbruck, Austria on Application of Advanced/Deep Neural Network in KG Construction</li>
                    </ul>
                </li>
            </ul>

            <p>Shodhguru Research Labs was the leading partner to organize the Winter School-2023 on November 06-10, 2023 under the theme of "LLM and KGs: Bridging the Gap", as a collocated event of 5th Knowledge Graph and Semantic Web Conference held at University of Zaragoza, Spain.</p>
            
            <ul>
            <p>Speakers:</p>
                <li>Ananya Hooda: "NLP in LLMs and KGs"</li>
                <li>Panos Alexopoulos: "LLMs and Ontology Engineering: Opportunities, Risks, and Strategies"</li>
                <li>Jennifer D'Souza: "Large Datasets and Large Language Models"</li>
                <li>Sarker, Bishnu: "Joint Forces of LLM and KG for biomedical Knowledge Discovery"</li>
                <li>Rajan Gupta: "Productizing Generative AI"</li>
                <li>Diego Collarana: "Knowledge Graph Treatments for Hallucinating LLMs"</li>
                <li>Neha Keshan: "Semantics as an aid towards building a FAIR-Trustworthy LLMs"</li>
                <li>Sanju Tiwari: "Open Research Knowledge Graph:An Overview"</li>
                <li>Tek Raj Chhetri: "Strengthening Data Governance and Trust: The Role of Semantic Web Technologies in the Age of Internet of Things and Large Language Models"</li>
                <li>Mayank Kejriwal: "Large language models and knowledge graphs: friends or foes?"</li>
            </ul>
            <h2>2024</h2>
            <p><b>Keynote Speaker:</b> Invited to represent status of Digital Twins in India, at BDTIC 2024 Congress at Barcelona, Spain on April 17, 2024.</p>
            
            <ul>
            
                <li><p>Technical Partner in Conferences:</p>
                    <ul>
                        <li><b>Artificial Intelligence:</b> Towards Sustainable Intelligence
Second International Conference, AI4S 2024, Alcala de Henares, Spain, October 3–4, 2024, Proceedings
https://link.springer.com/book/10.1007/978-3</li>
                        <li><b>Knowledge Graphs and Semantic Web</b>
6th International Conference, KGSWC 2024, Paris, France, December 11–13, 2024, Proceedings
https://link.springer.com/book/9783031812200#overview</li>
                        <li><b>Electronic Governance with Emerging Technologies</b>
Third International Conference, EGETC 2024, Jakarta, Indonesia, September 25–26, 2024
https://link.springer.com/book/10.1007/978-3-031-77029-6</li>

                    </ul>
                </li>
            </ul>

            <ul>
            
                <li><p>Leading Organizer of Winter School 2024 at KGSWC-2024 Conference on "Intersection of KGs and LLMs"</p>
                    <ul>
                        <li>Ananya, Harnessing Large Language Models for Advanced Knowledge Graph Visualization and Construction</li>
                        <li>Neha Keshan, Semantics as the Structural Foundation for Trustworthy LLMs: Like Cells in the Body of AI</li>
                        <li>Mayank Kejriwal, Neurosymbolic Architectures for achieving the best of two worlds</li>
                        <li>Nandana Mihindukulasooriya, Relation Extraction with Large Language Model: An Overview</li>
                        <li>Jennifer D´Souza, Harnessing Large Language Models as Research Assistants and in Science</li>
                        <li>Garima Agrawal, Enhancing LLMs with Knowledge Graphs: Integrating Structured Domain Knowledge</li>
                        <li>Joey Yip, Data and Knowledge-Driven Processes for the Knowledge Graph Lifecycle</li>
                        <li>Boris Villazón-Terrazas, Unlocking Synergies: Combining LLMs and Knowledge Graphs for Enhanced AI</li>
                        <li> Stratos Kontopoulos, Synergistic Applications of Large Language Models and Knowledge Graphs: A Case Study in Food Product Development</li>
                        <li>Tek Raj Chhetri, From Data to Knowledge: Driving Innovation with Knowledge Graphs	</li>
                        

                    </ul>
                </li>
            </ul>
            <h2>2025</h2>
            <table>
                <tr>
                    <td valign='top'><p><b>Technical Partner:</b> Faculty Development Program on Generative AI, Muffakham Jah College of Engineering & Technology, Hyderabad, India on 06-10 January, 2025.</p></td>
                    <td><img src={certi} alt="certi" height={200} width={300} /></td>
                </tr>
            
            </table>
        </div>
    );
}

export default CompletedProjects;
